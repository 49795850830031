(function() {
    angular.module('EntrakV5').controller('landingController', landingController);

    //stateParams.m?
    //stateParams.it?
    //stateParams.rt?
    function landingController($scope, $rootScope, $stateParams, $state, Service, IS_LOCAL) {
        console.log('landingController');

        // only for local testing, cannot use cookie
        if (IS_LOCAL && $stateParams.m && $stateParams.it) {
          Service.storageSave("et_method", $stateParams.m);
          Service.storageSave("et_it", $stateParams.it);
          Service.storageSave("et_rt", $stateParams.rt);
          // $rootScope.signInMethod = $stateParams.m;
          // $rootScope.idToken = $stateParams.it;
        }

        $state.go('tenant', null, {
            location: 'replace'
        });

        $scope.$on('$destroy', function() {
            console.log("landingController destroy");
        });

    }
})();
